<template>
  <div class="admin">
    <!-- 侧边栏 -->
    <div class="sidebar">
      <div class="sidebar_icon">
        <div class="sidebar_icon_img">
          <img
            src="https://offer.centurysouth.cn/img/title_icon.1e73eb74.png"
          />
        </div>
        <div class="sidebar_icon_title">
          <div class="sidebar_icon_title_left">世纪</div>
          <div class="sidebar_icon_title_right">南方</div>
        </div>
      </div>
      <div v-for="(item, index) in router_list" :key="index">
        <div
          v-if="item.isRoot"
          class="sidebar_item"
          :style="
            $route.path == item.path
              ? 'background-color: #f7dc18;box-shadow: 0 3px 10px #f7dd189d;'
              : ''
          "
          @click="goPath(item.path)"
        >
          <i :class="`iconfont ${item.icon}`"></i>
          <div class="sidebar_item_a">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- 右侧内容 -->
    <div class="content_box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 跳转路由列表
      router_list: [
        { name: "轮播管理", path: "/Admin", icon: "iconlunbotu" },
        { name: "专题管理", path: "/Admin/Topic", icon: "iconzhuanti1" },
        { name: "分类管理", path: "/Admin/Classification", icon: "iconziyuan" },
        {
          name: "方案管理",
          path: "/Admin/Program",
          icon: "iconyingxiaofangan",
        },
        { name: "素材管理", path: "/Admin/Material", icon: "iconsucaiku" },
        { name: "爬虫管理", path: "/Admin/Crawler", icon: "iconCrawler-file" },
        {
          name: "人员管理",
          path: "/Admin/Personnel",
          icon: "el-icon-user-solid",
        },
        { name: "活动平台", path: "/", icon: "iconpingtaizijiankong" },
      ],
      // 路由存储
      router_storage: "/Admin",
    };
  },
  created() {
    let status = null;
    if (this.$route.query.isRoot == "true") {
      status = true;
    } else {
      status = false;
    }
    this.router_list.map((item) => {
      item.isRoot = true;
      if (item.name == "人员管理") {
        item.isRoot = status;
      }
    });
    console.log(this.router_list);
    // console.log(this.$route.query.isRoot);
  },
  methods: {
    goPath(path) {
      this.router_storage = path;
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style scoped>
.admin {
  display: flex;
  background-color: #f4f2f7;
}
.sidebar {
  width: 170px;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  box-shadow: 5px 0 10px #e0e0e0;
}
.sidebar_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
}
.sidebar_icon_img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.sidebar_icon_img img {
  width: 100%;
  height: 100%;
}
.sidebar_icon_title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
}
.sidebar_icon_title_left {
  color: #f8222b;
}
.sidebar_icon_title_right {
  color: #000;
}
/* 跳转列表 */
.sidebar_item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  margin: 50px 0;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
}
.sidebar_item i {
  font-size: 18px;
}
.sidebar_item_a {
  font-size: 14px;
  font-weight: 600;
}
/* 内容盒子 */
.content_box {
  min-width: 1200px;
  margin: 0 auto;
}
</style>